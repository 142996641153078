<template>
  <div class="content">
    <h1 class="title">Спортивные звания</h1>

    <div v-if="isEditor" class="buttons">
      <b-button type="is-success" @click="handleEdit(null)">
        Добавить
      </b-button>
    </div>

    <b-table
      :data="ranks"
      :default-sort-direction="defaultSortDirection"
      :sort-icon="sortIcon"
      :sort-icon-size="sortIconSize"
      :mobile-cards="false"
      :loading="loading"
    >
      <b-table-column v-slot="props" label="#">
        {{ props.index + 1 }}
      </b-table-column>
      <b-table-column v-slot="props" field="name" label="Название" sortable>
        {{ props.row.name }}
      </b-table-column>
      <b-table-column v-slot="props" field="code" label="Код" sortable>
        {{ props.row.code }}
      </b-table-column>

      <b-table-column v-if="isEditor" v-slot="props" label="">
        <div class="buttons">
          <b-button
            type="is-success"
            icon-left="pencil"
            size="is-small"
            @click="handleEdit(props.row)"
          />
          <!--          <b-button-->
          <!--            v-if="props.row.createdBy || props.row.updatedBy"-->
          <!--            type="is-danger"-->
          <!--            icon-left="delete"-->
          <!--            size="is-small"-->
          <!--            @click="handleDelete(props.row)"-->
          <!--          />-->
        </div>
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import { Resource } from "@/common/const/common";
import { mapGetters } from "vuex";
import RankForm from "./RankForm";
import { DELETE_ERROR, DELETE_SUCCESS } from "@/common/const/message";

export default {
  name: "RanksIndex",

  data() {
    return {
      ranks: [],
      loading: true,

      defaultSortDirection: "asc",
      sortIcon: "arrow-up",
      sortIconSize: "is-small",
    };
  },

  computed: {
    ...mapGetters({
      isEditor: "auth/isEditor",
    }),
  },

  async mounted() {
    await this.loadData();
    document.title = "Спортивные звания";
  },

  methods: {
    async handleClose(isAdded) {
      if (isAdded) {
        await this.loadData();
      }
    },

    async handleEdit(entity) {
      const rank = entity ? { ...entity } : { name: "", code: "" };

      this.$buefy.modal.open({
        parent: this,
        component: RankForm,
        props: { rank },
        events: { close: this.handleClose },
        hasModalCard: true,
      });
    },

    async handleDelete({ id, name }) {
      this.$buefy.dialog.confirm({
        title: "Удаление звания",
        class: "is-size-4",
        message: `Звание ${name} будет удалено. Операция не обратима! Продолжить?`,
        cancelText: "Отмена",
        confirmText: "Да",
        hasIcon: true,
        type: "is-danger",
        onConfirm: this.handleRemove(id),
      });
    },

    async handleRemove(id) {
      try {
        this.loading = true;
        await this.$api[Resource.RANKS].delete(id);
        await this.loadData();
        this.$notifier.success(DELETE_SUCCESS);
      } catch (e) {
        this.$notifier.error(e.response.data.message || DELETE_ERROR);
      } finally {
        this.loading = false;
      }
    },

    async loadData() {
      try {
        this.loading = true;
        this.ranks = await this.$api[Resource.RANKS].get(`?_sort=name:asc`);
      } catch (e) {
        console.log(e);
        // await this.$router.push("/not-found");
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
