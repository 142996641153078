<template>
  <ValidationObserver
    v-slot="{ validate, invalid }"
    ref="form"
    tag="form"
    @submit.prevent="handleSubmit"
  >
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">
          {{ rank.id ? "Редактирование звания" : "Добавить звание" }}
        </p>
      </header>

      <section class="modal-card-body">
        <ValidationProvider
          v-slot="{ errors, valid }"
          rules="required|min:3"
          name="Название"
          slim
        >
          <b-field
            label="Название"
            :type="{ 'is-danger': errors[0], 'is-success': valid }"
            :message="errors[0]"
          >
            <b-input v-model="name" type="text" />
          </b-field>
        </ValidationProvider>

        <ValidationProvider
          v-slot="{ errors, valid }"
          rules="required|min:2|max:6"
          name="Код"
          slim
        >
          <b-field
            label="Код"
            :type="{ 'is-danger': errors[0], 'is-success': valid }"
            :message="errors[0]"
          >
            <b-input v-model="code" type="text" />
          </b-field>
        </ValidationProvider>
      </section>

      <footer class="modal-card-foot">
        <AppFormButtons
          :is-submit-disabled="invalid"
          :is-show-reset="false"
          @send="validate().then(handleSubmit)"
          @close="handleClose"
        />
      </footer>
    </div>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Resource } from "@/common/const/common";
import AppFormButtons from "@/common/components/AppFormButtons";
import { SAVE_ERROR, SAVE_SUCCESS } from "@/common/const/message";

export default {
  name: "RankForm",
  components: {
    ValidationObserver,
    ValidationProvider,
    AppFormButtons,
  },
  props: {
    rank: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      name: "",
      code: "",
    };
  },
  created() {
    this.name = this.rank.name;
    this.code = this.rank.code;
  },
  methods: {
    async handleClose() {
      this.$emit("close", false);
    },
    async handleSubmit() {
      const rank = {
        name: this.name,
        code: this.code.toUpperCase(),
      };

      try {
        if (this.rank.id) {
          await this.$api[Resource.RANKS].put(rank, this.rank.id);
        } else {
          await this.$api[Resource.RANKS].post(rank);
        }

        this.$notifier.success(SAVE_SUCCESS);
        this.$emit("close", true);
      } catch (e) {
        this.$notifier.error(e.response?.data?.message || SAVE_ERROR);
      }
    },
  },
};
</script>
